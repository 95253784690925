const Footer = () => {
  return (
    <div className="footer">
      <div className="container py-4">
        <div className="d-flex justify-content-between">
          <div className="col w-50">
            <img
              className="logo logoBottom"
              src="./assets/images/logos/logo.svg"
              alt=""
            />
          </div>
          {/*  <div className="d-flex justify-content-between align-items-center w-25 w-lg-50 w-md-50 w-sm-50 w-xs-50"> */}
          <div className="col-4 col-lg-4 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-between align-items-center">
            <i className="fa-brands fa-facebook-f"></i>
            <i className="fa-brands fa-x-twitter"></i>
            <i className="fa-brands fa-instagram"></i>
            <i className="fa-brands fa-linkedin-in"></i>
            <i className="fa-brands fa-youtube"></i>
          </div>
        </div>
        <hr className="line" />
        <div className="d-lg-flex d-md-flex justify-content-between align-items-end">
          <div className="col w-50">
            <h3>İletişim</h3>
            <p>
              <span className="bold-text">Türkiye:</span> +90 224 280 75 00 USA:
              +1 (813) 351 71 00 <span className="bold-text">Russia</span> +7
              925 608 28 80
            </p>
          </div>
          <div className="d-flex justify-content-end align-items-center w-50">
            <p>
              <span className="bold-text">Email:</span> info@akyapak.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
