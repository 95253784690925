const Separator = () => {
  return (
    <div className="col-lg-2 d-flex flex-lg-column justify-content-between justify-content-lg-around justify-content-md-between align-items-center my-4">
      <img
        className="separator line-verticale d-none d-lg-flex"
        src="./assets/images/svg/line-vertical.svg"
        alt=""
      />
      <hr className="d-lg-none line-horizontal" />
      <img
        className="separator"
        src="./assets/images/svg/separator.svg"
        alt=""
      />
      <hr className="d-lg-none line-horizontal" />
      <img
        className="separator line-verticale d-none d-lg-flex"
        src="./assets/images/svg/line-vertical.svg"
        alt=""
      />
    </div>
  );
};

export default Separator;
