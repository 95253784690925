import { useEffect, useState } from "react";
import PaginationSurvey from "./Pagination";
import WelcomeMessage from "./WelcomeMessage";
import Separator from "./Separator";
import Banner from "./Banner";
import SuccessModal from "./ModalSuccess";

const Dashboard = () => {
  const [selectedItemOne, setSelectedItemOne] = useState({
    title:
      "Akyapak’ı bir arkadaşınıza ya da iş ortağınıza tavsiye etme olasılığınız nedir?",
    values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    selected: 5,
    showComment: false,
    comment: undefined,
  });
  const [selectedItemTwo, setSelectedItemTwo] = useState({
    title:
      "Akyapak’ı bir arkadaşınıza ya da iş ortağınıza tavsiye etme olasılığınız nedir?",
    values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    selected: 5,
    showComment: false,
    comment: undefined,
  });
  const [selectedItemThree, setSelectedItemThree] = useState({
    title:
      "Akyapak’ı bir arkadaşınıza ya da iş ortağınıza tavsiye etme olasılığınız nedir?",
    values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    selected: 5,
    showComment: false,
    comment: undefined,
  });
  const [selectedItemFour, setSelectedItemFour] = useState({
    title:
      "Akyapak’ı bir arkadaşınıza ya da iş ortağınıza tavsiye etme olasılığınız nedir?",
    values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    selected: 5,
    showComment: false,
    comment: undefined,
  });
  const [selectedItemFive, setSelectedItemFive] = useState({
    title:
      "Akyapak’ı bir arkadaşınıza ya da iş ortağınıza tavsiye etme olasılığınız nedir?",
    values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    selected: 5,
    showComment: false,
    comment: undefined,
  });

  const [showModal, setShowModal] = useState({
    status: false,
    message: "Teşekkür ederiz...",
  });

  const [isSending, setIsSending] = useState({
    status: false,
    message: "Gonderiliyor...",
  });

  useEffect(() => {
    console.log("selectedItemOne ", selectedItemOne);
  }, [selectedItemOne]);

  const sendSurvey = () => {
    setIsSending({ status: true, message: "Lütefen bekleyiniz.." });
    setTimeout(() => {
      setIsSending({ status: false, message: "Gonderildi" });
      setShowModal({
        status: true,
        message: "İş birliğiniz ve katkınız için şimdiden teşekkür ederiz.",
      });
    }, 3000);
  };

  return (
    <>
      <Banner />
      <div className="container">
        <div className="row my-4">
          <WelcomeMessage />
          <Separator />
          <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
            <h1 className="survey-title">{selectedItemOne.title}</h1>
            <div className="row d-flex justify-content-center justify-content-lg-start justify-content-md-start align-items-center my-4">
              {selectedItemOne.values.map((item, i) => (
                <span
                  onClick={() =>
                    setSelectedItemOne({ ...selectedItemOne, selected: item })
                  }
                  key={i}
                  className={`d-flex justify-content-center align-items-center note-button rounded-circle mx-4 my-3 ${
                    item == selectedItemOne.selected ? "note-selected" : ""
                  }`}
                >
                  {item}
                </span>
              ))}
            </div>
            <div
              style={{ display: selectedItemOne.showComment ? "none" : "flex" }}
              className="col-12 d-flex flex-column"
            >
              <p
                onClick={() =>
                  setSelectedItemOne({ ...selectedItemOne, showComment: true })
                }
                className="comment"
              >
                Yorumunuz (Opsiyonel)
              </p>
              <hr className="w-100" />
            </div>
            <div
              style={{ display: selectedItemOne.showComment ? "flex" : "none" }}
              className="form col-12"
            >
              <textarea
                onFocus={() =>
                  setSelectedItemOne({ ...selectedItemOne, showComment: true })
                }
                onBlur={() =>
                  setSelectedItemOne({ ...selectedItemOne, showComment: false })
                }
                className="form-control"
                placeholder="Yorumunuzu burary yazınız"
                onChange={(val) =>
                  setSelectedItemOne({
                    ...selectedItemOne,
                    comment: val.target.value,
                  })
                }
              ></textarea>
            </div>
            <hr className="line" />
            <PaginationSurvey
              isLoading={isSending.status}
              nextClick={sendSurvey}
            />
          </div>
        </div>
        <SuccessModal
          message={showModal.message}
          show={showModal.status}
          onHide={() => setShowModal({ status: false, message: "" })}
        />
      </div>
    </>
  );
};

export default Dashboard;
