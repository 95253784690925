const WelcomeMessage = () => {
  return (
    <div className="col-lg-4">
      <h1 className="message-title">
        Sayın <span className="client-name">Saliha Akın,</span>
      </h1>
      <p className="message-description">
        Müşterilerimizin memnuniyetleri ve geri bildirimleri, AKYAPAK'ın
        önceliğidir ve en üst standartları karşılamak için sürekli çaba
        göstermekteyiz.
      </p>
      <p className="message-description">
        Size daha iyi hizmet sunabilmek ve işimizi daha etkili şekilde
        sürdürebilmek için bu anketi doldurmanızı rica ediyoruz.
      </p>
      <p className="message-description">
        İş birliğiniz ve katkınız için şimdiden teşekkür ederiz.
      </p>
      <img className="w-50" src="./assets/images/signature-ak.png" alt="" />
    </div>
  );
};

export default WelcomeMessage;
