import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Dashboard from "../../components/Dashboard";

const Home = () => {
  return (
    <>
      <Helmet>
        <title></title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>

      <Header />
      <Dashboard />

      <Footer />
    </>
  );
};

export default Home;
