import React from "react";
import { Image, Row, Col, Container, Button } from "react-bootstrap";
import bg1 from "../../assets/images/bg/bg1.png";


const NoPage = () => {
  return (
    <Container fluid className="nopage-container">
     
    </Container>
  );
};

export default NoPage;
