const Header = () => {
  const goToOurSite = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <div className="container header d-flex justify-content-between align-items-center mt-4 mb-4">
      <img className="logo" src="./assets/images/logos/logo.svg" alt="" />
      <div
        className="nav-right"
        onClick={() => goToOurSite("https://akyapak.com")}
      >
        <div>
          <span className="">Sitemizi ziyaret edin</span>
          <i className="fa-solid fa-chevron-right"></i>
        </div>
        <hr className="line" />
      </div>
    </div>
  );
};

export default Header;
