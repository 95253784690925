const PaginationSurvey = ({ nextClick, isLoading }) => {
  return (
    <div className="pagination-button d-flex flex-column w-50 align-self-end">
      <button className="w-100 btn p-0 m-0" disabled={isLoading} onClick={nextClick}>
        <div className="d-flex justify-content-between align-items-center">
          <span>İleri</span>
          {isLoading ? (
            <i class="fas fa-circle-notch fa-spin"></i>
          ) : (
            <i className="fas fa-chevron-right"></i>
          )}
        </div>
        <hr className="line mt-2" />
      </button>
    </div>
  );
};

export default PaginationSurvey;
